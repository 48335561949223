import React from 'react';
import { userType } from 'types';
import { colors } from 'globalStyle';
import { openPopUp } from 'store/popup';
import { useDispatch } from 'react-redux';
import { Ghost } from 'components/Buttons';
import { Title, Text } from 'components/Texts';
import PersonIcon from 'assets/icons/person.svg';
import CompanyIcon from 'assets/icons/company.svg';
import { Box, Grid, styled } from '@material-ui/core';
import ModalContent from 'components/Modals/ModalContent';

export default function RegisterType({ isDesktop, onClose, onFinish }) {
  const dispatch = useDispatch();
  return (
    <ModalContent
      pt="10px"
      onClose={onClose}
      fullSize={!isDesktop}
      pb={!isDesktop && '80px'}
      px={isDesktop ? '94px' : '40px'}
      width={isDesktop ? '666px' : '100%'}
      height={isDesktop ? '530px' : '100%'}
      borderRadius={isDesktop ? '20px' : '0px'}
    >
      <Grid container direction="column">
        <Title size={isDesktop ? 24 : 20} isCenter>¿Cómo te quieres registrar?</Title>
        <Text
          mt={16}
          isCenter
          mb={isDesktop ? 62 : 74}
          size={isDesktop ? 18 : 14}
        >
          Crea una cuenta para invertir en nuestros proyectos.
        </Text>
        <RCardContainer container justify="space-between">
          <RTypeCard
            color={colors.secondary}
            img={PersonIcon}
            isDesktop={isDesktop}
            title="Inversionista"
            onClick={() => onFinish(userType.USER)}
            description="Quieres invertir y hacer que tu dinero crezca"
          />
          <RTypeCard
            color={colors.primary}
            img={CompanyIcon}
            isDesktop={isDesktop}
            title="Receptor"
            onClick={() => onFinish(userType.COMPANY)}
            description="Empresa con NIT colombiano"
          />
        </RCardContainer>
        <Text mt={36} mb={35} size={14} color={colors.GRAY_2} isCenter>
          ¿Ya tienes una cuenta?
          <Ghost
            ml={5}
            isSubmit
            size={14}
            underline
            color={colors.primary}
            onClick={() => dispatch(openPopUp({ type: 'login' }))}
          >
            Inicia Sesión
          </Ghost>
        </Text>
      </Grid>
    </ModalContent>
  );
}

const RTypeCard = ({
  img, color, title, description, isDesktop, onClick,
}) => (
  <RTypeCardRoot
    container
    color={color}
    onClick={onClick}
    alignItems="center"
    isDesktop={isDesktop}
    direction={isDesktop ? 'column' : 'row'}
  >
    <Box
      width={isDesktop ? '108px' : '100px'}
      height="100px"
      display="flex"
      bgcolor={color}
      marginBottom={isDesktop ? '20px' : '0px'}
      borderRadius={isDesktop ? '0px 0px 20px 20px' : '0px 20px 20px 0px'}
    >
      <Box
        src={img}
        width="64px"
        height="64px"
        margin="auto"
        component="img"
        alignSelf="center"
      />
    </Box>
    <RCardTextContainer container direction="column" alignItems="center" isDesktop={isDesktop}>
      <Title size={16} isCenter={isDesktop} mb={13}>{title}</Title>
      <Text size={14} isCenter={isDesktop} width="160px" color="#667080" fullWidth>{description}</Text>
    </RCardTextContainer>
  </RTypeCardRoot>
);

const RCardContainer = styled(Grid)({
  rowGap: '53px',
});

const RTypeCardRoot = styled(Grid)((props) => ({
  cursor: 'pointer',
  width: props.isDesktop ? '200px' : '310px',
  height: props.isDesktop ? '260px' : '150px',
  boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.06)',
  borderRadius: '15px',
  border: props.isDesktop ? '0px' : `1px solid ${props.color}`,
  '&:hover': {
    border: `1px solid ${props.color}`,
  },
}));

const RCardTextContainer = styled(Grid)((props) => ({
  width: props.isDesktop ? '100%' : 'auto',
  marginLeft: props.isDesktop ? '0px' : '20px',
  maxWidth: !props.isDesktop && 'calc(100% - 130px)',
  '& div': {
    maxWidth: '100%',
  },
}));
