import React, { useState } from 'react';
import { userType } from 'types';
import { Title, Text } from 'components/Texts';
import { useUser } from 'hooks';
import { Letter } from 'components/Letter';
import { colors, Div, weight } from 'globalStyle';
import moment from 'moment';
import styled from 'styled-components';
import { capitalizeFirstLetterString } from 'util/capitalizeFirstLetter';
import { Ghost } from 'components/Buttons';
import { routes } from 'routes';
import Ceilings from 'page/Profile/Company/Ceilings';
import { CardBalance } from './components/AvailableMoney/CardBalance';
import { NotificationCard } from './components/NotificationCard';
import { ChangePassword } from './components/ChangePassword';
import { ChangePasswordCompany } from './components/ChangePasswordCompany';

export const Menu = () => {
  const [modal, setModal] = useState(false);
  const [ceilingsPopUp, setCeilingsPopUp] = useState(false);
  const { type, user } = useUser();
  const username = `${capitalizeFirstLetterString(
    user?.info?.firstName,
  )}  ${capitalizeFirstLetterString(user?.info?.firstLastName)}`;
  const companyName = capitalizeFirstLetterString(user?.info?.name);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleCeilingPopUp = () => {
    setCeilingsPopUp(!ceilingsPopUp);
  };
  return (
    <MainCard>
      <Div isFlex direction="column" justify="space-between" height="100%">
        <Div
          width="100%"
          isFlex
          direction="column"
          justify="space-between"
          height={type === userType.COMPANY ? '15%' : '17%'}
        >
          <Letter
            size={40}
            name={type === userType.USER ? username : companyName}
          />
          <Title
            size={16}
            align="center"
            title={type === userType.USER ? username : companyName}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {type === userType.USER ? username : companyName}
          </Title>

          <Text
            size={12}
            align="center"
            fontStyle="italic"
            color={colors.GRAY_6}
          >
            Últ. ingreso:
            {' '}
            {moment(user?.info?.lastLogin).format('D [de] MMM [a las] h:mm a')}
            {' '}
          </Text>
        </Div>

        {type === userType.COMPANY && (
          <Ghost
            size={12}
            onClick={() => {
              handleCeilingPopUp();
            }}
            isSubmit
            underline
            italic
            color={colors.primary}
            weight={weight.SEMI_BOLD}
          >
            Configuración de topes
          </Ghost>
        )}

        <Div height={type === userType.COMPANY ? '34%' : '32%'} width="100%">
          <CardBalance />
        </Div>

        <Div height="33%" width="100%">
          <NotificationCard />
        </Div>

        <Div
          height="8%"
          width="100%"
          isFlex
          direction="column"
          justify="space-between"
        >
          <Ghost size={12} isSubmit underline onClick={handleModal} italic>
            Cambiar contraseña
          </Ghost>
          <Ghost size={12} underline href={routes.message.contactUs} italic>
            Centro de ayuda
          </Ghost>
        </Div>
      </Div>
      {ceilingsPopUp && <Ceilings open={ceilingsPopUp} onClose={handleCeilingPopUp} />}
      {modal && (
        <>
          {type === userType.USER ? (
            <ChangePassword isOpen={modal} onClick={handleModal} />
          ) : (
            <ChangePasswordCompany isOpen={modal} onClick={handleModal} />
          )}
        </>
      )}
    </MainCard>
  );
};

const MainCard = styled.div`
  flex-basis: 20%;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: ${colors.background.WHITE};
  border-radius: 20px;
  padding: 20px;
  height: 100%;
`;
