import React, { useEffect, useState } from 'react';

import { documentsType, accountTypes, userType } from 'types';

import { useMediaQuery, useUser } from 'hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import {
  InputFormatNumber,
  ErrorText,
  Select,
  Input,
  InputOnlyNumbers,
  CheckBox,
} from 'components/Form';
import {
  colors, Div, Figure, weight,
} from 'globalStyle';
import { StarIcon } from 'icons';
import { TextLight } from 'components/Texts';
import { Submit } from 'components/Buttons';
import { interbankInversionist, interbankCompany } from 'services';
import closeIcon from 'assets/icons/closeIcon.svg';
import { PopUpInvestmentWithdrawal } from 'page/components/PopUpInvestmentWithdrawal';
import RegisterChecker from 'Layout/components/Navbar/components/Register/components/RegisterChecker';

const stylesInput = {
  textAlign: 'start',
  fontSize: '14px',
};

export const WithdrawMoney = ({
  onClick,
  next,
  defaultValue,
  listBanks,
  handleSuccess,
  goToFavorite,
  handleClose,
  isOpen,
  fetch,
  ...props
}) => {
  const {
    formProps,
    isLoading,
    setLoading,
    setError,
    errorMessage,
    addData,
  } = props;
  const [openInvestmentWithdrawal, setOpenInvestmentWithdrawal] = useState(
    false,
  );
  const [filterBankType, setFilterBankType] = useState(
    Object.values(accountTypes),
  );
  const idTypeBank = formProps.watch('idTypeBank');
  useEffect(() => {
    const bank = listBanks.find((item) => item.name === idTypeBank);
    if (bank) {
      const accountType = bank?.accountTypes?.map((item) => accountTypes[item]);
      setFilterBankType(accountType || []);
    }
  }, [idTypeBank]);

  const { type } = useUser();

  const onSubmitInterbank = async (form) => {
    let removeFavorite = false;

    if (form.isFavorite === false && defaultValue?.isFavoriteSelected) {
      removeFavorite = true;
    }

    setLoading(true);
    if (type === userType.USER) {
      interbankInversionist({
        nameBank: form.idTypeBank,
        amount: parseInt(form.amount.replaceAll('.', ''), 10),
        idTypeDocument: form.idTypeDocument,
        destinationDni: form.dni,
        destinationName: form.name,
        destinationAccount: form.destinationAccount,
        accountType: form.idTypeCount,
        isFavorite: form.isFavorite,
        removeFavorite,
      })
        .then(({ data }) => {
          addData({
            voucherNumber: data.voucherNumber,
            transactionDateTime: data.transactionDateTime,
            originAccount: data.originAccount,
            gmf: data.gmf,
            cost: data.cost,
            iva: data.iva,
            nameBank: form.idTypeBank,
            amount: parseInt(form.amount.replaceAll('.', ''), 10),
            destinationDni: form.dni,
            destinationName: form.name,
            destinationAccount: form.destinationAccount,
            accountType: form.idTypeCount,
          });
          setLoading(false);
          fetch();
          handleSuccess('transferSuccess');
        })
        .catch(({ messageFormat }) => {
          setLoading(false);
          setError(true, messageFormat);
        });
    } else {
      interbankCompany({
        nameBank: form.idTypeBank,
        amount: parseInt(form.amount.replaceAll('.', ''), 10),
        idTypeDocument: form.idTypeDocument,
        destinationDni: form.dni,
        destinationName: form.name,
        destinationAccount: form.destinationAccount,
        accountType: form.idTypeCount,
        isFavorite: form.isFavorite,
        removeFavorite,
      })
        .then(({ data }) => {
          addData({
            voucherNumber: data?.voucherNumber,
            transactionDateTime: data?.transactionDateTime,
            originAccount: data?.originAccount,
            gmf: data?.gmf,
            cost: data?.cost,
            iva: data?.iva,
            nameBank: form.idTypeBank,
            amount: parseInt(form.amount.replaceAll('.', ''), 10),
            destinationDni: form.dni,
            destinationName: form.name,
            destinationAccount: form.destinationAccount,
            accountType: form.idTypeCount,
          });
          setLoading(false);
          handleSuccess('transferSuccess');
          fetch();
        })
        .catch(({ messageFormat, message }) => {
          if (message === 'CANT_WITHDRAW_MONEY_OPEN_PROJECTS') {
            setOpenInvestmentWithdrawal(true);
          }
          setLoading(false);
          setError(true, messageFormat);
        });
    }
  };
  const isDesktop = useMediaQuery(1023);

  const handlePopUp = () => {
    setOpenInvestmentWithdrawal(false);
    handleClose();
  };

  if (openInvestmentWithdrawal) {
    return (
      <PopUpInvestmentWithdrawal
        open={openInvestmentWithdrawal}
        handleOpen={handlePopUp}
      />
    );
  }

  return (
    <RegisterChecker check={isOpen} onClose={handleClose}>
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            borderRadius: '20px',
            padding: `${isDesktop ? '30px' : '20px'}`,
          },
        }}
        maxWidth="md"
        onClick={(e) => e.stopPropagation()}
      >
        <DialogContent
          style={{
            width: `${isDesktop ? 'calc(960px - 60px)' : 'auto'}`,
            padding: '0',
            overflowY: 'initial',
            overflowX: isDesktop && 'hidden',
          }}
          align="center"
        >
          <Div
            isFlex
            justify="space-between"
            margin="0 0 20px"
            onClick={() => {
              handleClose();
            }}
          >
            <Figure
              style={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
            >
              <img src={arrowLeft} alt="arrowLeft" />
            </Figure>
            <Div>
              <TextLight size={20} color={colors.primary} weight={weight.BOLD}>
                Retiro
              </TextLight>
            </Div>
            <Figure
              style={{
                width: '18px',
                height: '18px',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <img src={closeIcon} alt="cerrar" />
            </Figure>
          </Div>
          <Div p={`${isDesktop ? '0 90px' : '0 10px'}`}>
            <TextLight size={13} mb={3}>
              En caso de retirar es importante validar que cobros te puede hacer
              tu entidad de destino.
            </TextLight>
            <TextLight size={13}>
              Esta operación tiene un costo de:
              <span style={{ fontWeight: weight.BOLD, margin: '0 3px' }}>
                $ 5.950 COP + GMF (Gravamen al Movimiento Financiero)
              </span>
              Si aplica.
            </TextLight>

            <form>
              <Div isFlex direction="column" margin="20px 0 0">
                <TextLight
                  size={15}
                  align="end"
                  color={colors.primary}
                  style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                  fontStyle="italic"
                  weight={weight.SEMI_BOLD}
                  onClick={() => {
                    goToFavorite();
                  }}
                >
                  Ver favoritos
                </TextLight>
                <DivForm isDesktop={isDesktop}>
                  <Select
                    label="Banco"
                    name="idTypeBank"
                    defaultValue={defaultValue?.bankName}
                    style={{
                      width: `${isDesktop ? '330px' : '100%'}`,
                      ...stylesInput,
                    }}
                    {...formProps}
                  >
                    {listBanks.map((bank) => (
                      <option key={bank} value={bank.name}>
                        {bank.name}
                      </option>
                    ))}
                  </Select>
                  <Select
                    label="Tipo de cuenta"
                    name="idTypeCount"
                    defaultValue={defaultValue?.accountType}
                    style={{
                      width: `${isDesktop ? '330px' : '100%'}`,
                      ...stylesInput,
                    }}
                    {...formProps}
                  >
                    {filterBankType?.map((banktype) => (
                      <option key={banktype} value={banktype}>
                        {banktype}
                      </option>
                    ))}
                  </Select>
                </DivForm>
                <DivForm isDesktop={isDesktop}>
                  <InputOnlyNumbers
                    title="Número de cuenta"
                    name="destinationAccount"
                    defaultValue={defaultValue?.accountNumber}
                    {...formProps}
                    validate={{
                      length: (value) => value.length >= 4 || 'Mínimo 4 dígitos',
                    }}
                    style={{
                      width: `${isDesktop ? '330px' : '100%'}`,
                      ...stylesInput,
                    }}
                  />
                  <Input
                    name="name"
                    type="text"
                    placeholder="Nombre (s) y Apellidos"
                    label="Nombre completo del titular"
                    defaultValue={defaultValue?.name}
                    validate={{
                      max: (value) => /^[a-zA-Z ]+$/g.test(value)
                        || 'No puede contener caracteres diferentes a letras',
                    }}
                    style={{
                      width: `${isDesktop ? '330px' : '100%'}`,
                      ...stylesInput,
                    }}
                    {...formProps}
                  />
                </DivForm>
                <DivForm isDesktop={isDesktop}>
                  <Select
                    label="Tipo de documento"
                    name="idTypeDocument"
                    defaultValue={defaultValue?.documentType}
                    style={{
                      width: `${isDesktop ? '330px' : '100%'}`,
                      ...stylesInput,
                    }}
                    {...formProps}
                  >
                    {documentsType.map((document) => (
                      <option key={document.idType} value={document.name}>
                        {document.detail}
                      </option>
                    ))}
                  </Select>
                  <InputOnlyNumbers
                    label="Número de documento"
                    name="dni"
                    placeholder="9897673858"
                    defaultValue={defaultValue?.dni}
                    style={{
                      width: `${isDesktop ? '330px' : '100%'}`,
                      ...stylesInput,
                    }}
                    {...formProps}
                  />
                </DivForm>
                <DivForm isDesktop={isDesktop}>
                  <InputFormatNumber
                    title="Valor a transferir"
                    name="amount"
                    placeholder=""
                    style={{
                      width: `${isDesktop ? '330px' : '100%'}`,
                    }}
                    styles={stylesInput}
                    {...formProps}
                  />
                  <Div style={stylesInput} isFlex justify="flex-start">
                    <TextLight size={13} fontStyle="italic">
                      <span
                        style={{ fontWeight: weight.BOLD, marginRight: '3px' }}
                      >
                        Recuerda:
                      </span>
                      Tener tu dinero en Bloom no te genera ningún tipo de cobro
                      y siempre puedes reinvertir tus rendimientos.
                    </TextLight>
                  </Div>
                </DivForm>
                <Div isFlex margin="15px 0">
                  <StarIcon size="17px" />
                  <CheckBox
                    mb="0"
                    style={{ cursor: 'pointer', textAlign: 'left', margin: 0 }}
                    name="isFavorite"
                    defaultValue={defaultValue?.isFavoriteSelected}
                    {...formProps}
                    required={false}
                  >
                    Agregar a favoritos
                  </CheckBox>
                </Div>
                <ErrorText isCenter text={errorMessage} />
                <Submit
                  style={{
                    width: `${isDesktop ? '330px' : '100%'}`,
                    height: '45px',
                  }}
                  size={14}
                  isLoading={isLoading}
                  isSubmit
                  disabled={formProps.valid}
                  onClick={formProps.handleSubmit(onSubmitInterbank)}
                >
                  Hacer transferencia
                </Submit>
              </Div>
            </form>
          </Div>
        </DialogContent>
      </Dialog>
    </RegisterChecker>
  );
};

export const DivForm = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? 'row' : 'column')};
  justify-content: space-between;
  width: 100%;
  column-gap: 60px;
`;
