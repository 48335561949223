import React from 'react';
import {
  colors, Div, weight, Figure,
} from 'globalStyle';
import { TextLight } from 'components/Texts';
import { Letter } from 'components/Letter';
import { userType } from 'types';
import {
  capitalizeFirstLetterString,
  capitalizeFirstLetterPhrase,
} from 'util/capitalizeFirstLetter';
import moment from 'moment';
import { useUser } from 'hooks';
import logOut from 'assets/icons/logout-white.svg';

import { Dropdown } from './Dropdown';

export const SiderbarIsLogged = ({ handleChangePassword }) => {
  const { type, user, logout } = useUser();
  const username = capitalizeFirstLetterPhrase(
    `${user?.info?.firstName} ${user?.info?.firstLastName}`,
  );
  const companyName = capitalizeFirstLetterString(user?.info?.name);

  return (
    <>
      <Div isFlex justify="flex-start" style={{ columnGap: '15px' }}>
        <Letter
          size={40}
          name={type === userType.USER ? username : companyName}
        />
        <Div isFlex justify="space-between" direction="column">
          <TextLight size={16} color={colors.WHITE} weight={weight.BOLD} mb={5}>
            {type === userType.USER ? username : companyName}
          </TextLight>

          <TextLight
            size={12}
            fontStyle="italic"
            color={colors.WHITE}
          >
            {` Últ. ingreso:
            ${moment(user?.info?.lastLogin).format(
              'D [de] MMM [a las] h:mm a',
            )}`}
          </TextLight>
        </Div>
      </Div>
      <TextLight
        size={12}
        color={colors.WHITE}
        ml={57}
        mt={5}
        style={{ textDecoration: 'underline' }}
        weight={weight.MEDIUM}
        onClick={() => {
          handleChangePassword();
        }}
      >
        Cambiar contraseña
      </TextLight>
      <Div p="0 0 0 15px" margin="20px 0 0">
        <Dropdown type={type} />
      </Div>
      <Div
        isFlex
        style={{ columnGap: '15px' }}
        onClick={() => logout()}
        margin="30px 0 0"
      >
        <Figure width="17px" height="17px">
          <img
            src={logOut}
            alt="Cerrar sesión"
            style={{ width: '100%', height: '100%' }}
          />
        </Figure>
        <TextLight color={colors.WHITE} size={14} width="auto">
          Cerrar sesión
        </TextLight>
      </Div>
    </>
  );
};
