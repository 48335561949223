import React, { useState } from 'react';
import {
  Row, weight, colors, Figure,
} from 'globalStyle';
import Box from '@material-ui/core/Box';
import { ArrowDown } from 'icons';
import { Modal } from 'components/Modals';
import { TextLight } from 'components/Texts';
import styled from 'styled-components';
import { Routes } from 'Layout/components/profile.routes';
import { Letter } from 'components/Letter';
import { capitalizeFirstLetterPhrase } from 'util/capitalizeFirstLetter';
import { useLocation, Link } from 'wouter';

export const VerticalMenu = ({
  names = 'Bloom',
  type,
  handleClick,
  hasCompany,
}) => {
  const [state, setState] = useState(false);
  const [location] = useLocation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{ borderLeft: `2px solid ${colors.input.inactive}`, padding: '0 20px', cursor: 'pointer' }}
      onClick={() => setState(!state)}
    >
      <Div>
        <Row vCenter allRight={10}>
          <Letter size={29} name={names} />
          <TextLight
            size={15}
            weight={weight.MEDIUM}
            color={colors.GRAY_1}
            title={capitalizeFirstLetterPhrase(names)}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
            }}
          >
            {capitalizeFirstLetterPhrase(names)}
          </TextLight>
          <Figure
            height="15px"
            width="15px"
            style={{
              transform: state && 'rotate(180deg)',
              transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            <ArrowDown
              style={{ cursor: 'pointer', color: colors.input.active }}

            />
          </Figure>
        </Row>
        <ProfileMemu
          style={{ display: state ? 'block' : 'none' }}
          onClick={() => setState(!state)}
        >
          <Routes hasCompany={hasCompany} type={type}>
            {({ name, href }) => (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                marginBottom="10px"
                height="30px"
                borderBottom={`1px solid ${colors.input.inactive}`}
                style={{ columnGap: '10px', cursor: 'pointer' }}
              >
                <Box
                  width="2px"
                  height="95%"
                  style={{
                    backgroundColor: location === href && colors.primary,
                  }}
                />
                <Link href={href}>
                  <TextLight
                    size={14}
                    weight={location === href ? weight.BOLD : weight.MEDIUM}
                    color={location === href && colors.primary}
                  >
                    {name}
                  </TextLight>
                </Link>
              </Box>
            )}
          </Routes>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            height="30px"
            style={{ cursor: 'pointer' }}
          >
            <TextLight
              color={colors.tertiary}
              size={14}
              weight={weight.MEDIUM}
              onClick={handleClick}
              ml={12}
            >
              Cerrar sesión
            </TextLight>
          </Box>
        </ProfileMemu>
        <Modal
          isTransparent
          opacity={0}
          isOpen={state}
          handleClick={() => setState(!state)}
        />
      </Div>
    </Box>
  );
};

export const Div = styled.div`
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ProfileMemu = styled.div`
  margin: 0;
  border-radius: 10px;
  width: 230px;
  height: auto;

  background-color: ${colors.WHITE};
  position: absolute;
  padding: 1.2em 1em;
  top: 60px;
  right: 0px;
  border: 1px solid ${colors.GRAY_4};
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.34);

  a {
    width: 100%;
    text-align: left;
  }
`;

export const NotificationBox = styled.div`
  position: relative;
  margin-bottom: 18px;
`;

export const Notification = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.tertiary};
  border-radius: 50%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
`;
