/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Div } from 'globalStyle';
import { Text } from 'components/Texts';
import { Grid, styled } from '@material-ui/core';
import { StyledCheckBox, ContentCheckBox } from './styled';
import { ErrorText } from './ErrorText';

export const CheckBox = ({
  mb,
  required,
  label,
  name,
  isCenter,
  register,
  errors,
  children,
  errorText,
  background,
  defaultValue,
  color,
  number,
  textPlacement = 'left',
  ...rest
}) => (
  <Div mb={mb || 18} isFlex>
    {textPlacement === 'left' && (
      <TextContainer color={color} label={label} number={number}>
        {children}
      </TextContainer>
    )}
    <ContentCheckBox background={background} {...rest}>
      <StyledCheckBox
        name={name}
        defaultChecked={defaultValue}
        type="checkbox"
        isError={errors[name] && errors[name]}
        ref={register({
          required: required ? 'Este campo es obligatorio' : false,
        })}
        {...rest}
      />
      <i />
      {textPlacement === 'right' && (
        <TextContainer color={color} label={label} number={number}>
          {children}
        </TextContainer>
      )}
    </ContentCheckBox>
    {errorText && <ErrorText text={errors[name]?.message} />}
  </Div>
);

const TextContainer = ({
  label, children, color, number,
}) => (
  <Container container alignContent="center">
    <Text pl={10} color={color} number={number || 4}>{label || children}</Text>
  </Container>
);

const Container = styled(Grid)({
  minHeight: '30px',
  verticalAlign: 'middle',
});
